export const messages = {
  ra: {
    action: {
      add: "Añadir",
      update: "Actualizar",
      add_filter: "Añadir filtro",
      back: "Volver",
      bulk_actions:
        "1 item seleccionado |||| %{smart_count} items seleccionados",
      cancel: "Cancelar",
      clear_input_value: "Limpiar valor",
      clone: "Clonar",
      close: "Cerrar",
      close_menu: "Cerrar menú",
      confirm: "Confirmar",
      create: "Crear",
      delete: "Eliminar",
      edit: "Editar",
      expand: "Expandir",
      export: "Exportar",
      list: "Listar",
      open_menu: "Abrir menú",
      refresh: "Refrescar",
      remove: "Borrar",
      remove_filter: "Borrar filtro",
      save: "Guardar",
      search: "Buscar",
      show: "Mostrar",
      sort: "Ordenar",
      undo: "Deshacer",
      publish: "Publicar",
      select: "Seleccionar",
      unselect: "Deseleccionar",
      move_up: "Mover arriba",
      move_down: "Mover abajo",
      accept: "Aceptar",
      restore: "Restaurar",
      discard: "Descartar",
      send: "Enviar",
    },
    auth: {
      auth_check_error: "Por favor inicie sesión para continuar",
      logout: "Cerrar Sesión",
      password: "Contraseña",
      sign_in: "Acceder",
      sign_in_error: "La autenticación falló, por favor, vuelva a intentarlo",
      user_menu: "Perfil",
      username: "Usuario",
      change: "CAMBIAR",
      email: "Correo electrónico",
      title: "INICIO DE SESIÓN",
      forgot: "Olvidé mi contraseña",
    },
    forgot: {
      title: "Recuperar contraseña",
      subtitle: "Ingrese su email para poder realizar el cambio.",
      email: "Email",
      button: "Cambiar contraseña",
      password: "Ingrese una contraseña",
      password_confirm: "Confirme la contraseña",
      message: "Se ha enviado un email para cambiar la contraseña",
    },
    boolean: {
      true: "Sí",
      false: "No",
      null: " ",
    },
    input: {
      file: {
        upload_several:
          "Arrastre algunos archivos para subir o haga clic para seleccionarlos.",
        upload_single:
          "Arrastre un archivo para subir o haga clic para seleccionarlo.",
      },
      image: {
        upload_several:
          "Arrastre algunas imágenes para subir o haga clic para seleccionarlas.",
        upload_single:
          "Arrastre alguna imagen para subir o haga clic para seleccionarla.",
      },
      references: {
        all_missing: "No se pueden encontrar datos de referencias.",
        many_missing:
          "Al menos una de las referencias asociadas parece no estar disponible.",
        single_missing: "La referencia asociada no parece estar disponible.",
      },
      password: {
        toggle_visible: "Ocultar contraseña",
        toggle_hidden: "Mostrar contraseña",
      },
    },
    message: {
      about: "Acerca de",
      are_you_sure: "¿Está seguro?",
      bulk_delete_content:
        "¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?",
      bulk_delete_title:
        "Eliminar %{name} |||| Eliminar %{smart_count} %{name} items",
      delete_content: "¿Seguro que quiere eliminar este item?",
      delete_title: "Eliminar %{name} #%{id}",
      details: "Detalles",
      error:
        "Se produjo un error en el cliente y su solicitud no se pudo completar",
      invalid_form: "Faltan completar campos requeridos para guardar",
      loading: "La página se está cargando, espere un momento por favor",
      no: "No",
      not_found:
        "O bien escribió una URL incorrecta o siguió un enlace incorrecto.",
      yes: "Sí",
      unsaved_changes:
        "Algunos cambios no fueron guardados. ¿Desea salir de todos modos?",
      max_select: "Solo se pueden seleccionar %{max} elementos",
      continue: "¿Desea continuar?",
      restore: "¿Estás seguro que desea restaurarlo?",
    },
    navigation: {
      next: "Siguiente",
      no_more_results:
        "El número de página %{page} está fuera de los límites. Pruebe la página anterior.",
      no_results: "No se han encontrado resultados",
      page_out_from_begin: "No puede ir antes de la página 1",
      page_out_from_end: "No puede ir después de la última página",
      page_out_of_boundaries: "Número de página %{page} fuera de los límites",
      page_range_info: "%{offsetBegin} - %{offsetEnd} de %{total}",
      page_rows_per_page: "Filas por página:",
      prev: "Anterior",
      skip_nav: "Saltar Nav",
    },
    sort: {
      sort_by: "Ordenar por %{field} %{order}",
      ASC: "ascendente",
      DESC: "descendente",
    },
    notification: {
      bad_item: "Elemento incorrecto",
      canceled: "Acción cancelada",
      created: "Elemento creado",
      data_provider_error:
        "Error del proveedor de datos. Consulte la consola para más detalles.",
      deleted: "Elemento borrado |||| %{smart_count} elementos borrados.",
      http_error: "Error de comunicación con el servidor",
      item_doesnt_exist: "El elemento no existe",
      logged_out: "Su sesión ha finalizado, vuelva a conectarse.",
      updated:
        "Elemento actualizado |||| %{smart_count} elementos actualizados",
      i18n_error:
        "No se pudieron cargar las traducciones para el idioma especificado",
      element_restored: "Se restauro correctamente el elemento",
      element_restored_fail: "No se pudo restaurar el elemento",
      settings_save: "Configuración guardada",
      ticket_created: "Ticket creado con éxito",
      copy_elements: "¡Elementos copiados!",
      link_used: "El link ingresado se encuentra en uso",
      note_preview_error:
        "No se pudo generar el preview para esta nota. Intente más tarde.",
    },
    page: {
      create: "Crear %{name}",
      dashboard: "Home",
      edit: "%{name} #%{id}",
      empty: "Sin %{name} todavía.",
      error: "Algo salió mal",
      invite: "¿Quiere agregar una?",
      list: "Lista de %{name}",
      loading: "Cargando",
      not_found: "No encontrado",
      show: "%{name} #%{id}",
    },
    validation: {
      email: "Debe ser un correo electrónico válido",
      maxLength: "Debe contener %{max} caracteres o menos",
      maxValue: "Debe ser %{max} o menos",
      minLength: "Debe contener %{min} caracteres al menos",
      minValue: "Debe ser al menos %{min}",
      number: "Debe ser un número",
      oneOf: "Debe ser uno de: %{options}",
      regex: "Debe coincidir con un formato específico (regexp): %{pattern}",
      required: "Requerido",
      password_mismatch: "Las contraseñas deben coincidir",
      field_required: "El campo es requerido",
    },
  },
  seo: {
    title_extension: "Extensión de título óptima",
    title_extension_helper: "Debe tener entre 20 y 70 caracteres con espacios",
    has_image: "Tiene una imagen principal",
    image_include_epigraph: "La imagen incluye un epígrafe",
    include_volanta: "Incluye volanta",
    include_pompadour: "Incluye copete",
    pompadour_extension: "Extensión de copete óptima",
    pompadour_extension_helper:
      "Debe tener entre 50 y 120 caracteres con espacios",
    content_extension: "Extensión del contenido óptima",
    content_extension_helper: "Se deben superar las 500 palabras",
    use_bold: "Utiliza negritas",
    include_links: "Incluye enlaces internos",
    include_links_helper:
      "Si incluye, al menos, un enlace a otra página del sitio, ya sea utilizando “Puede interesarte” o linkeando",
    has_tag: "Tiene al menos una etiqueta",
    has_author: "Tiene un autor",
    keywords: "Tiene al menos 3 palabras clave definidas",
    meta_description: "Incluye una meta descripción",
    meta_description_helper:
      "Es lo que se mostrará en los buscadores. Recomendamos no superar los 120 caracteres",
    meta_description_include_keywords:
      "La meta descripción incluye las palabras clave",
    title_include_keywords:
      "El título incluye al menos una de las palabras clave",
    assistant: "Asistente SEO",
    approved_validations: "Validaciones aprobadas",
  },
  components: {
    focal_point: "Punto focal",
    locked: "Bloqueado",
    locked_title: "Lo siento, %{name} tiene posesión de este recurso",
    locked_no_permissions: "No tienes permiso",
    locked_text:
      "Póngase en contacto con esa persona para obtener los permisos.",
    free_content: "Contenido Free",
    premium_content: "Contenido Premium",
    paper_content: "Contenido Papel",
    category_name: "Nombre categoría",
    edit_category: "Editar Categoría",
    edit_author: "Editar autor",
    edit_subscribe_active: "Editar suscripción activa para",
    name: "Nombre",
    medio: "Medio",
    delete_components: "Se eliminará el componente.",
    total_views: "Total de visitas",
    date: "Fecha",
    see_all: "Ver todas",
    fact_data: "Datos de facturación",
    order_detail: "Detalle de la orden",
    advertiser: "Anunciante",
    guest: "Invitado",
    phone: "Teléfono",
    business_name: "Razón social",
    unique_tax: "CUIL/CUIT",
    tax_attribute: "Condición frente al IVA",
    description_too_long: "Descripción demasiado larga",
    no_answer: "No contesto",
    no_audio_comp: "Su navegador no es compatible con el elemento de audio.",
    no_video_comp: "Su navegador no soporta el elemento de vídeo.",
    title: "Titulo",
    currency_sign: "$",
    history: "Historial",
    entries: "entradas",
    without_entries: "Sin entradas",
    new_subscription_for: "Nueva suscripción para ",
    new_subscription: "Nueva suscripción",
    subscription_helper:
      "*La suscripción vigente será cancelada con la creación de una nueva. En caso de ser el mismo plan, será renovada.",
    select_audio: "Seleccionar audio",
    copy_to_assemble: "Copiar para armar",
    advertising_type: "Tipo publicidad",
    plan_status: "Estado del Plan",
    problem: "Problema",
    improvement_suggestion: "Sugerencia de mejora",
    technical_support: "Asistencia técnica",
    attachScreenshot: "Adjuntar captura de pantalla",
    optional: "Opcional",
    priority: "Prioridad",
    priorities: {
      urgent: "Urgente",
      high: "Alta",
      medium: "Media",
      low: "Baja",
    },
    description: "Descripción",
    description_problem_text:
      "Por favor, describí lo más que puedas el problema o la sugerencia, incluyendo la sección donde te sucedió el problema, tu dispositivo y el navegador que estabas usando.",
    create_ticket_text:
      "Se creará un ticket de manera automática y recibirás un correo con todas las actualizaciones que el equipo de soporte vaya realizando en el mismo.",
    locale_utc: " UTC-3",
    currency: "ARS",
    locale_language: "es-AR",
    subscriber: "Suscriptor",
    admin: "Usuario",
    card: "Tarjeta",
    black: "Negro",
    white: "Blanco",
    start_date: "Fecha de inicio",
    add_line: "Agregar linea",
    add_in_notes: "Agregar en notas",
    add_user: "Agregar nuevo usuario",
    add_author: "Agregar nuevo autor",
    previous_subscription: "Suscripciones anteriores",
    no_plan: "Sin Plan",
    expired: "Vencimiento",
    payment_date: "Fecha de pago",
    amount: "Monto",
    new_profile: "Crear nuevo Perfil",
    edit_profile: "Editar perfil",
    current_subscribe: "Suscripción Actual",
    cards: "Tarjetas asociadas a",
    no_card: "Sin tarjeta",
    address: "Dirección",
    edit_user: "Editar usuario registrado",
    password: "Contraseña",
    change_password: "Cambiar contraseña",
    profile: "Perfil",
    view_online: "Ver online",
    deleted: "Eliminados",
    enabled: "Habilitado",
    disabled: "Deshabilitado",
    object: "Objeto base",
    attribute: "Nombre atributo",
    define_object: "Definir objeto",
    reduced_view: "Mostrar en vista reducida",
    possession: "Posesión",
    editing: "Editando",
    component: "Componente",
    created_by: "Creado por",
    last_update: "Última modificación",
    share: "Compartir en redes",
    shared_content:
      "Este contenido ya fue compartido en los últimos 30 días en",
    preview: "Vista previa",
    restore_note: "Restaurar copia de seguridad",
    previous_note: "Existe una versión más reciente de la nota",
    select_component: "Seleccionar el componente a mostrar",
    internet: "Internet",
    suggest: "Sugerir",
    paper: "Papel",
    free: "Free",
    premium: "Premium",
    update: "Actualizar",
    add: "Agregar",
    audio: "Audio",
    gallery: "Galería",
    selected: "seleccionada",
    news: "Noticias",
    category: "Categoría",
    video: "Video",
    interested: "Puede interesarte",
    multimedia_url: "URL del contenido multimedia",
    multimedia_url_helper: "Utilizado solo para optimizar el SEO",
    update_last_days: "Actualizado en los últimos 30 días",
    search_news: "Buscar Noticia",
    news_not_found:
      " No se han encontrado noticias con estos criterios de búsqueda.",
    loading_tags: "Cargando etiquetas...",
    loading_categories: "Cargando categorías...",
    main_category: "Categoría principal",
    other_categories: "Otras categorías",
    select_to_copy: "Seleccionar a donde copiar el contenido",
    copy_to: "Copiar contenido a ...",
    copy: "Copiar a ",
    copy_paper: "Copiar a Papel",
    copy_internet: "Copiar a Internet",
    select_fields: "Seleccionar campos",
    select_fields_to_copy: "Seleccioná los campos que quieras copiar",
    press_enter: "Presione enter para separar las palabras",
    chars: "Caracteres",
    long_title: "Título demasiado largo",
    chars_with_blank: "Caracteres con espacios",
    chars_without_blank: "Caracteres sin espacios",
    select_medio: "Proveniencia de la nota (medio)",
    title_section: "Título de la sección",
    subtitle_section: "Subtítulo de la sección",
    title_banner: "Título del banner",
    show_icon_premium: "Mostrar icono premium",
    text: "Texto",
    button_text: "Texto del botón",
    background: "Color Fondo",
    font_color: "Color Fuente",
    show_copete: "Mostrar copete",
    image: "Imagen",
    main_image: "Imagen Principal",
    select_image: "Seleccionar imagen",
    note: "Nota",
    notes: "Notas",
    hero_form_title: "Tipo de hero",
    image_position: "Posición de la imagen",
    image_style: "Estilo imagen",
    position: "Posición",
    type: "Tipo",
    advertising: "Publicidad",
    none: "Ninguno",
    all: "Todas",
    modality: "Modalidad",
    modalities: {
      chat: "Por Chat",
      channel: "Por un Canal",
    },
    whatsapp_number: "Número de WhatsApp",
    whatsapp_text: "Texto predefinido WhatsApp",
    whatsapp_link_channel: "Link al canal de WhatsApp",
    exclude_categories: "Excluir categorías",
    iframe_full_width: {
      tooltip: "Solo el número en pixeles",
      size: "Alto del iframe",
    },
    imagen_note_form: {
      help_image:
        "Recomendamos que sea una imagen de 300x400 px o relación de aspecto 3:4",
    },
    positions: {
      top: "Arriba",
      bottom: "Abajo",
      center: "Centro",
    },
    list_positions: {
      center: "Centro",
      top_left: "Superior izquierdo",
      top_right: "Superior derecho",
      bottom_left: "Inferior izquierdo",
      bottom_right: "Inferior derecho",
    },
    size: "Tamaño",
    height: "Alto",
    sizes: {
      cover_name: "Rellenar",
      cover_tip:
        "La imagen irá al 100% del ancho. Importante: si es una imagen vertical, puede que los márgenes superior e inferior no se visualicen.",
      contain_name: "Contenido",
      contain_tip:
        "La imagen se contendrá dentro del componente. Importante: si es una imagen vertical, quedará con espacios vacíos en los costados.",
    },
    size_scale: {
      big: "Grande",
      medium: "Mediano",
      small: "Chico",
    },
    audio_per_page: "Audios por pág:",
    image_per_page: "Imágenes por pág:",
    news_per_page: "Noticias por pág:",
    corrected_status_change: "Cambiará el estado de la corrección.",
    free_editor: "Editor free",
    premium_editor: " Editor premium",
    paper_editor: "Editor papel",
    select_news: "Seleccionar Noticia",
    catalog_list: {
      Bomba: "Bomba",
      GrillaX3: "Grilla x3",
      GrillaX4: "Grilla x4",
      GrillaX6: "Grilla x6",
      GrillaX9: "Grilla x9 - Con publicidad",
      Grilla3X3: "Grilla 3 medianas + 3 chicas",
      GrillaX5: "Grilla x5",
      GrillaX4WithoutImg: "Grilla x4 - Sin imágenes",
      GrillaX8WithPrimaryCard: "Grilla x8 - Con noticia destacada",
      GrillaX8: "Grilla x8 - Con publicidad",
      GridX5WithAdvertising: "Grilla x5 - Con Publicidad",
      GrillaX8WithoutPrimaryCard: "Grilla x8 - Sin noticia destacada",
      GridX7WithAdvertising: "Grilla x7 - Con Publicidad",
      GridX7WithPrimaryCard: "Grilla x7 -  Con noticia destacada",
      Gridx6TwoFeatured: "Grilla x6 -  Con dos noticias destacadas",
      GrillaSimple: "Grilla sin imágenes",
      Grilla3XMax: "Grilla 1 destacada + 2 secundarias",
      GridLastNews: "Grilla x4 + sección Últimas Noticias",
      IndividualGridHover: "Grilla x4 - Con animación",
      GridCardPublish: "Grilla x6 - Con Publicidad",
      Hero: "Imagen Hero",
      Publicidad: "Publicidad",
      Publicidadx4: "Grilla Publicidad x 4",
      ImagenNotas: "Imagen + 2 Notas",
      WhatsApp: "Noticias por WhatsApp",
      SubscribeCTA: "Banner Suscripción",
      IframeFullWidth: "Iframe Ancho completo",
    },
  },
  resources: {
    types: {
      name: "Tipo Entidad",
      confirm_title:
        "Se eliminará este tipo de entidad y las entidades generadas. Esta acción no puede deshacerse. ¿Deseas eliminarla igualmente?",
      confirm_content: "",
      new: "Crear",
      fields: {
        name: "Nombre",
        attributes: "Atributos",
        attribute: "Atributo",
        format: "Formato de campo",
        options: "Opciones",
        option: "Valor",
        show: "Mostrar en nota",
      },
    },
    relations: {
      name: "Relaciones",
      fields: {
        name: "Nombre",
        entity_id: "Entidad origen",
        related_id: "Entidad destino",
        relation_type_id: "Tipo de relacion",
      },
    },
    relation_types: {
      name: "Tipo Relaciones",
      fields: {
        name: "Nombre",
        type_id: "Tipo de entidad",
      },
    },
    entities: {
      name: "Entidad",
      plural_name: "Entidades",
      confirm_title:
        "Se eliminará la entidad %{name}. Esta acción no puede deshacerse. ¿Deseas eliminarla igualmente?",
      confirm_content: "",
      confirm_title_bulk:
        "Se eliminarán la/s %{smart_count} entidad/es y sus relaciones con los contenidos. Esta acción no se puede deshacer. ¿Aún quieres eliminarlos?",
      new: "Crear",
      fields: {
        name: "Nombre",
        order: "Ordenamiento",
        description: "Descripción",
        short_description: "Descripción corta",
        type_id: "Tipo",
        attributes: "Atributos",
        field: "Atributo",
        value: "Valor",
        slug: "Slug",
      },
    },
    metrics: {
      name: "Métricas",
      validation: {
        not_found: "No se encontraron datos para el periodo seleccionado",
        empty_message: "Por favor, ingresá un rango de fechas para comenzar.",
        date_order: "'Hasta' no puede ser menor a 'Desde'.",
        invalid:
          "Error al enviar la solicitud, por favor, revise los campos resaltados.",
      },
      titles: {
        content_per_day: "Contenido creado por día",
        content_per_user: "Contenido creado por usuario",
        content_only_free: "Contenidos solo FREE",
        content_only_premium: "Contenidos solo PREMIUM",
        content_free_premium: "Contenidos FREE y PREMIUM",
        other_content: "Otros contenidos",
        content_total: "Contenido total creado",
        characters_free: "Caracteres promedio por contenido FREE",
        characters_premium: "Caracteres promedio por contenido PREMIUM",
        characters_paper: "Caracteres promedio por contenido PAPEL",
        content_paper: "Contenidos PAPEL",
        title_users: "Usuarios",
        title_subscriptions: "Métricas",
        title_history: "Historial de Registrados",
        filter_registered: "Usuarios Registrados",
        sub_by_plan: "Suscriptores por plan",
        singups: "Altas",
        signrenews: "Altas y renovaciones totales",
        optouts: "Bajas totales",
        comments: "Comentarios totales",
        churn_rate: "Churn Rate",
        sub_url: "URL previo a suscripción",
        url: "URL",
        bounced: "Rebotes",
        unfulfilled: "No completados",
        fulfilled: "Completados",
        successful: "Suscriptos",
        count_users: "Total de visitas",
        objective_content: "Objetivo de contenido",
        date_created: "Fecha de creación",
        note_id: "ID de Nota",
        category: "Categoría",
      },
      descriptions: {
        bounced: "El usuario no seleccionó ningún plan.",
        unfulfilled:
          "El usuario seleccionó un plan, pero no superó la instancia de registro o pago.",
        fulfilled:
          " El usuario completó el proceso de pago, independientemente de si este último se procesó correctamente o no",
        successful: "El usuario se suscribió correctamente",
        count_users:
          "Cantidad de veces que un usuario visito la página, contando desde la primera vez. Cuenta las notas accedidas directamente.",
        show: "Visualizar la nota en la página.",
      },
      input: {
        from: "Desde",
        to: "Hasta",
        users: "Filtrar por usuario",
      },
    },
    share: {
      network: "Red social",
      message: "Texto",
      status: "Estado de publicación",
      status_confirm_title: "Seleccioná cómo compartir el contenido",
      notification: {
        success: "Nota compartida con éxito",
        error: "Ocurrio un error al compartir la nota",
      },
    },
    pages: {
      name: "Portadas",
      successMessage: "Cambios generados con éxito",
      fields: {
        name: "Nombre",
        updated_at: "Última modificación",
        locked_by: "Último acceso",
        config: "Configuración",
        show_section: "Mostrar espacio",
        position: "Posición de la imagen",
        position_center: "Centrado",
        position_top: "Arriba",
        position_bottom: "Abajo",
        type_hero: "Tipo de hero",
        type_hero_large: "Grande",
        type_hero_small: "Chico",
        type_hero_medium: "Mediano",
        title_section: "Título de la sección",
        description: "Descripción",
        count_section: "Cantidad de titulares",
        link_section: "Link de destino",
        link_sponsor: "Link del auspiciante",
        sponsor: "Sponsor",
        link_iframe: "Link del reproductor",
        show_tags: "Mostrar tag categoría",
        show_author: "Mostrar autor ",
        show_section_copete: "Mostrar copete",
        category: "Categorías",
        color: "Tipo y color del alerta",
        fontcolor: "Color de Fuente",
        background: "Color de Fondo",
        helper_title_image: "Imagen título 150x50 px",
      },
    },
    notes: {
      name: "Contenido",
      successMessage: "Contenido generados con éxito",
      validation: {
        date_publish: "Los minutos deben ser '00' ó '30'",
      },
      alert_autosave: "Se encontraron cambios sin guardar",
      slug_helper_text: "No se permiten espacios ni caracteres especiales",
      seo_helper:
        "Para ayudar a posicionar aún mejor en los buscadores, como Google.",
      keywords_helper: "Aquellas palabras que indican de qué va el contenido.",
      meta_description_helper:
        "Lo que resume el contenido. Ayudará a aumentar tu tráfico desde buscadores.",
      observation_helper: "Consideraciones para el editor y el armador",
      bulk_actions: {
        restore_label: "Restaurar",
        restore_confirm_title: "Restaurar las noticias",
        restore_confirm_content:
          "¿Está seguro de que desea restablecer estos elementos?",
        status_label: "Estado de publicación",
        status_confirm_title: "Modificar estado de las notas seleccionadas",
        category_id_label: "Categoría",
        category_id_confirm_title:
          "Modificar categorías de las notas seleccionadas",
        author_id_label: "Autor",
        author_id_confirm_title:
          "Modificar el autor de las notas seleccionadas",
      },
      notification: {
        success: "El cambio se ha realizado con éxito",
        error: "Error al actualizar los datos",
      },
      format: {
        standard: "Estándar",
        audio: "Audio",
        gallery: "Galería",
        video: "Video",
      },
      user_interest: {
        inform_name: "Infórmame",
        inform_tip:
          "Tiene como objetivo mantener informado al usuario. A modo de ejemplo, un suceso específico en la ciudad",
        educate_name: "Edúcame",
        educate_tip:
          "Tiene como objetivo contribuir con el nivel educativo del usuario. A modo de ejemplo, el abordaje sobre nuevas formas eficientes de alimentación",
        distract_name: "Distráeme",
        distract_tip:
          "Tiene como objetivo entretener al usuario de manera distendida. A modo de ejemplo, un abordaje sobre la farándula",
        inspire_name: "Inspírame",
        inspire_tip:
          "Tiene como objetivo motivar al usuario. A modo de ejemplo, historias de personas resilientes",
      },
      status: {
        draft: "Borrador",
        publish: "Publicar",
        published: "Publicada",
        scheduled: "Programada",
        schedule: "Programar",
        deleted: "Eliminado",
      },
      corrected: {
        corrected: "Corregido",
        dont_corrected: "No Corregido",
      },
      fields: {
        content: "Contenido",
        content_premium: "Contenido premium",
        title: "Título",
        slug: "Link de la nota",
        search: "Buscar",
        date_publish_paper: "Fecha de publicación papel",
        format: "Formato",
        created_at: "Fecha de creación",
        date_publish: "Fecha de publicación",
        title_home: "Título de la nota en home",
        author: "Nombre del Autor",
        author_id: "Autor",
        status: "Estado",
        section_paper: "Sección papel",
        creator_id: "Creador",
        page: "Página",
        priority_paper: "Prioridad",
        page_paper: "Página Papel",
        keywords: "Palabras clave",
        meta_description: "Meta descripción",
        volanta: "Volanta",
        copete: "Copete",
        corrected: "Contenido corregido",
        epigrafe: "Epígrafe",
        enable_comments: "Permite comentarios",
        observation: "Comentarios",
        is_cover_note_paper: "Nota de Tapa",
        fixed: "Corregida",
        built: "Armada",
        revised: "Revisada",
        date_publish_paper_short: "Fecha papel",
        category_id: "Categoría Internet",
        type_content: "Tipo de contenido",
        deleted_at: "Eliminado",
        date_web: "Fecha Internet",
        interest_user_category: "Categoría de interés de usuario",
      },
    },
    journalists: {
      name: "Usuarios",
      new: "Crear",
      fields: {
        first_name: "Nombre",
        last_name: "Apellido",
        dni: "DNI",
        password: "Contraseña",
        confirm_password: "Confirmar contraseña",
        password_confirmation: "Confirmar contraseña",
        search: "Buscar",
        roles: "Perfil",
      },
      errors: {
        password_mismatch: "Las contraseñas no coinciden.",
      },
    },
    subscribers: {
      name: "Registrados",
      new: "Crear",
      fields: {
        first_name: "Nombre",
        last_name: "Apellido",
        password: "Contraseña",
        dni: "DNI",
        confirm_password: "Confirmar contraseña",
        phone_feature: "Cód. de área",
        phone: "Teléfono",
        birthday: "Fecha de nacimiento",
        dealer: "Canillita o Kiosco",
        address: "Dirección",
        addr_floor: "Piso",
        addr_door: "Dpto",
        city: "Ciudad",
        country: "País",
        province: "Provincia",
        zipcode: "Código Postal",
        password_confirmation: "Confirmar contraseña",
        search: "Buscar",
        roles: "Plan",
        username: "Nombre de usuario",
        cards_asoc: "Tarjetas asociadas",
        expire_date: "Fecha de expiración",
        status: "Estado",
      },
      status: {
        active: "activa",
        renewed: "renovada",
        expired: "vencida",
        canceled: "cancelada",
      },
      export: {
        phone_feature: "Caracteristica de telefono",
        phone: "Telefono",
        birthday: "Fecha nacimiento",
        name: "Nombre",
        no: "No",
        yes: "Si",
        direction: "Direccion",
        printed: "Impresa",
        subscription: "Suscripcion",
        status_subscription: "Estado suscripcion",
        status: {
          active: "Activo",
          expired: "Vencido",
          cancelled: "Cancelada",
          unsubscribed: "Sin suscripcion",
        },
      },
      errors: {
        password_mismatch: "Las contraseñas no coinciden.",
        export: "Error al convertir a csv",
      },
    },
    authors: {
      name: "Autores",
      new: "Crear",
      fields: {
        display_name: "Nombre y Apellido",
        first_name: "Nombre",
        last_name: "Apellido",
        description: "Descripción",
        notes_count: "Entradas",
        search: "Buscar",
      },
    },
    categories: {
      name: "Categorias",
      new: "Crear",
      fields: {
        name: "Nombre",
        description: "Descripción",
        search: "Buscar",
        notes_count: "Contenidos asociados",
      },
      confirm_title:
        "Se eliminará esta categoría. Esta acción no puede deshacerse. ¿Deseas eliminarla igualmente?",
      confirm_content: "",
      disable_delete:
        "No se puede eliminar esta categoría. Primero elimine los contenidos asociados.",
    },
    photographers: {
      name: "Fotógrafos",
      new: "Crear",
      fields: {
        name: "Nombre",
        description: "Descripción",
        search: "Buscar",
      },
    },
    tags: {
      name: "Etiquetas",
      new: "Crear",
      fields: {
        name: "Nombre",
        description: "Descripción",
        search: "Buscar",
      },
      confirm_title:
        "Se eliminará la etiqueta '%{name}' y sus relaciones con los contenidos. Esta acción no puede deshacerse. ¿Deseas eliminarla igualmente?",
      confirm_title_bulk:
        "Se eliminará/n %{smart_count} etiqueta/s y sus relaciones con los contenidos. Esta acción no puede deshacerse. ¿Deseas eliminarla/s igualmente?",
      confirm_content: "",
    },
    roles: {
      name: "Perfiles",
      new: "Crear",
      fields: {
        name: "Código",
        title: "Nombre",
        users_count: "Cant. usuarios",
        search: "Buscar",
        type: "Tipo usuario",
      },
    },
    settings: {
      name: "Configuraciones",
      singular_name: "Configuración",
      fields: {
        name: "Nombre",
        val: "Valor",
        type: "Tipo",
        search: "Buscar",
      },
    },
    combos: {
      name: "Combos",
      label: "Espacios / Combos",
      new: "Nuevo Combo / Espacio",
      edit: "Editar Combo",
      order: "Ordenar espacios / combos",
      define:
        "Definí tanto el orden de los medios como de los espacios dentro de estos",
      types: {
        short_text_name: "Respuesta corta",
        short_text_help: "De hasta 80 caracteres. Ej: Nombre y apellido",
        paragrahp_name: "Párrafo",
        paragrahp_help:
          "De hasta 255 caracteres. Ideal para solicitar descripciones más largas, como el texto para un aviso",
        paragrahp_label: "Máx caracteres",
        email_name: "Email",
        email_help: "Para recabar el correo electrónico del anunciante",
        date_name: "Fecha",
        date_help:
          "Para que el anunciante pueda especificar una fecha o un rango de fechas",
        date_type: "Tipo",
        multiple: "Multiple",
        range: "Rango",
        simple: "Simple",
        date_range: "Disposición",
        free: "Libre",
        from_now: "Desde hoy",
        to_now: "Hasta hoy",
        disabled_days: "Marcá los días que quieras deshabilitar",
        days: {
          monday: "Lunes",
          tuesday: "Martes",
          wednesday: "Miércoles",
          thursday: "Jueves",
          friday: "Viernes",
          saturday: "Sábado",
          sunday: "Domingo",
        },
        quantity_days: "Cantidad máxima de días",
        quantity_days_help: "Cantidad de dias que puede elegir",
        number: "Número",
        number_help: "Para que el anunciante pueda agregar cantidades",
        check: "Casillas de verificación",
        check_help:
          "Ideal para ofrecer opciones donde el anunciante deba elegir una o varias",
        radio: "Lista de opciones",
        radio_help:
          "Ideal para cuando el anunciante deba elegir solo una de las opciones, las cuales no necesitan descripción",
        card: "Tarjetas de opciones",
        card_help:
          "Ideal para cuando el anunciante deba elegir solo una de las opciones y estas necesiten descripción",
        image_card: "Tarjetas de opciones con imágenes",
        image_card_help:
          "Ideal para cuando el anunciante deba elegir solo una de las opciones y estas necesiten descripción y/o imagen",
        file: "Carga de Archivo",
        file_help:
          "Para que el anunciante pueda cargar un archivo de formato determinado. Puede ser una imagen, un video o un audio",
        file_type: "Tipo de archivo",
      },
      fields: {
        search: "Buscar",
        name: "Nombre",
        admedia_id: "Medio",
        price: "Precio",
        price_discount: "Precio con descuento",
        details: "Detalles del espacio",
        config: "Configuración de los steps",
        recommend: "Recomendado",
        active: "Activo",
        multiple: "Permite seleccionar multiples",
        steps: {
          step: "Step",
          type: "Tipo",
          question: "Texto descriptivo",
          checkout_text: "Texto en resumen",
          helper_text: "Texto de ayuda",
          required: "Requerida",
          add_step: "Agregar step",
          add_field: "AGREGAR CAMPO DE FORMULARIO",
          add_option: "AGREGAR OPCION",
        },
      },
      confirm_remove_step: {
        title: "Remover el formulario",
        message: "¿Estás seguro que desea eliminar el formulario?",
      },
      tooltips: {
        lines_description:
          "Agregá todo lo que incluye el espacio / combo. Podés agregar un máximo de 5 líneas.",
        steps:
          "Todos los datos, fechas o archivos que requerirás al anunciante para la publicación en este espacio / combo.",
        question:
          "Texto que indica al anunciante qué es lo que debe ingresar en este campo. Por ejemplo, 'Nombre'",
        checkout_text:
          "Es el texto que se mostrará al anunciante en el resumen de su pedido. En algunos casos, puede coincidir con el 'Texto descriptivo'",
      },
      duplicate: "Duplicar",
    },
    amedia: {
      name: "Medios",
      fields: {
        search: "Buscar",
        name: "Nombre",
        description: "Descripción",
      },
      new: "Nuevo Medio",
      edit: "Editar Medio",
    },
    orders: {
      name: "Órdenes",
      edit: "Editar Orden",
      space: "Espacio / combo",
      order_status: {
        pending: "Pendiente de revisión",
        in_review: "En revisión",
        ready: "Lista para publicar",
        publish: "Publicada",
      },
      payment_status: {
        pending_payment: "Pendiente",
        processing: "Procesando",
        complete: "Exitoso",
        failed: "Falló",
        nocomplete: "No completado",
      },
      payment_method: {
        mp: "Mercado Pago",
        pa: "Paypal",
        ma: "Manual",
      },
      fields: {
        created_at: "Fecha de Creación",
        status: "Estado de la orden",
        payment_method: "Método de pago",
        payment_status: "Estado del pago",
        cost: "Precio",
        reference: "Referencia",
        combo_id: "Nombre del espacio / combo",
      },
    },
    publicly: {
      name: "Publicly",
    },
    submenuMemberships: {
      name: "Memberships",
    },
    polls: {
      name: "Encuestas",
      new: "Crear",
      fields: {
        question: "Pregunta",
        date_question: "Comienzo Encuesta",
        type: "Tipo",
        search: "Buscar",
        active: "Activo",
        option_1: "Opción 1",
        option_2: "Opción 2",
        option_3: "Opción 3",
        option_4: "Opción 4",
        option_5: "Opción 5",
        selected_1: "Cantidad de votos 1",
        selected_2: "Cantidad de votos 2",
        selected_3: "Cantidad de votos 3",
        selected_4: "Cantidad de votos 4",
        selected_5: "Cantidad de votos 5",
      },
    },
    media: {
      name: "Biblioteca Multimedia",
      fields: {
        title: "Título",
        description: "Descripción",
        alt_text: "Texto alternativo",
        photographer: "Fotógrafo",
        caption: "Epígrafe",
        preview_url: "Vista previa",
        source_url: "Link",
        media_type: "Tipo de archivo",
        watermark: "Añadir marca de agua",
        search: "Buscar",
        apply_crop: "Aplicar recorte",
        discard_crop: "Descartar",
        title_crop: "Ajusta tu imagen",
      },
      image_maxFilesize:
        "El tamaño no debe exceder los %{maxSize}. En caso de superarlo, el archivo no se subirá.",
      image_cropped: `El tamaño no debe exceder los %{maxSize}. En caso de superarlo, el archivo no se subira.
         Se permite recortar de a una sola imagen.`,
      audio_maxFilesize:
        "El tamaño del archivo no puede exceder %{maxSize}. En caso de superarlo, el archivo no se subirá.",
      maxFilesizeMultiple:
        "El tamaño máximo de los archivos no puede superar los 80MB",
      selectExisting: "Seleccionar existente",
    },
    newspapers: {
      name: "Edición impresa",
      fields: {
        title: "Título",
        date_publish: "Fecha de la edición",
        file_url: "Link",
        thumbnail_url: "Vista previa",
        search: "Buscar",
        category: "Categoría",
      },
      maxFilesize: "El tamaño del archivo no puede exceder 5MB",
    },
    comments: {
      name: "Comentarios",
      detail: "Detalles del comentario",
      fields: {
        user_id: "Usuario",
        note_id: "Noticia",
        date_gte: "Posted since",
        date_lte: "Posted before",
        created_at: "Fecha",
        comment: "Comentario",
        parent_id: "En respuesta a",
        status: "Estado",
      },
      action: {
        accept: "Aprobar",
        reject: "Rechazar",
      },
      notification: {
        approved_success: "Comentario aprobado",
        approved_error: "Error: El comentario no se pudo aprobar",
        rejected_success: "Comentario rechazado",
        rejected_error: "Error: El comentario no se pudo rechazar",
      },
    },
    suscriptors: {
      name: "Suscriptores pagos",
    },
    plans: {
      name: "Planes",
      new: "Crear",
      fields: {
        name: "Nombre del plan",
        price: "Precio Mensual",
        price_total: "Precio Final",
        dolar_price: "Precio en dolares",
        price_discount: "Precio Mensual con descuento",
        type: "Frecuencia de cobro",
        created_at: "Fecha",
        recommend: "Recomendar",
        locally: "Este plan es exclusivo para la localidad",
        active: "Mostrar en la página",
        lines_description: "Descripción",
        role_id: "Perfil asociado",
        external: "Pasarela de pago",
        external_reference: "Referencia",
        subscriptions_count: "Suscriptores",
        has_free_trial: "Tiene prueba gratuita",
      },
      tooltips: {
        lines_description: "Información del plan que se muestra en la web",
      },
      helper: {
        price_discount: "Dejar vacio si no incluye bonificación",
        price: "Al cliente se le cobrará $%{price} de manera anual",
        active:
          "No se puede mostrar un plan que no tenga asociado un método de pago",
      },
      free_trial_mp: {
        title: "Prueba Gratuita",
      },
      info_create_cards:
        'Este plan incluye el club de beneficios. De confirmar, iniciará automáticamente el pedido de una tarjeta. IMPORTANTE: en caso de ya tener una, el proceso de "alta de tarjeta" queda sin efecto.',
    },
    club: {
      name: "Benefits",
    },
    benefits: {
      name: "Beneficios",
      fields: {
        search: "Buscar",
        title: "Titulo",
        category: "Categoria",
        description: "Descripción",
        short_description: "Descripción corta",
        current_image: "Imagen actual",
        shop: "Comercio",
      },
    },
    shop: {
      name: "Comercios",
      fields: {
        search: "Buscar",
        name: "Nombre del comercio",
        address: "Dirección",
        cuit: "CUIT",
        owner_name: "Propietario o persona de contacto",
        dni: "DNI",
        email: "Email",
        phone: "Teléfono",
        benefit_desc: "Descripción",
        short_desc: "Descripción corta",
        agreement_date: "Fecha aceptación",
      },
    },
    club_cards: {
      name: "Tarjetas",
      new: "Crear",
      empty_title: "Aún no hay tarjetas creadas.",
      empty_body: "¿Quiere agregar una?",
      validation: {
        user_not_dni: "El suscriptor no tiene completo el DNI",
      },
      fields: {
        search: "Buscar por nombre",
        dni: "DNI",
        headline: "Tarjeta titular",
        user_id: "Suscriptor",
        first_name: "Nombre",
        last_name: "Apellido",
        username: "Nombre y apellido",
        email: "Email",
        phone: "Teléfono",
        birthday: "Fecha de nacimiento",
        state: "Estado de tarjeta",
        printed: "Impresa",
        active: "En vigencia",
        address: "Dirección",
        addr_floor: "Piso",
        addr_door: "Dpto",
        city: "Ciudad",
        country: "País",
        province: "Provincia",
        delivery_date: "Fecha de entrega",
        type: "Tipo de tarjeta",
        notification_plana: "Notificar a Plan A",
      },
      bulk_actions: {
        print_label: "Marcar impresa",
        print_confirm_title: "Actualizar impresiones",
        print_confirm_content:
          "¿Está seguro que quiere marcar las tarjetas seleccionadas como impresas?",
        status_label: "Cambiar estado",
        status_confirm_title: "Selecciona el nuevo estado de la/s tarjeta/s",
      },
      notification: {
        success: "El cambio se ha realizado con éxito",
        error: "Error al actualizar los datos",
      },
      info_has_card: "Este suscriptor ya posee una tarjeta",
    },
    users: {
      name: "Usuarios",
    },
    general: {
      name: "General",
    },
    web: {
      name: "Página web",
    },
    membership: {
      name: "Memberships",
    },
    subscriptor: {
      name: "Suscriptores",
    },
  },
};
